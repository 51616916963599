window.fetchdata = function () {
  "use strict";
  const _self = this;

  this.getMeta = function (metaName) {
    const metas = document.getElementsByTagName('meta');
    for (let i = 0; i < metas.length; i++) {
      if (metas[i].getAttribute('name') === metaName) {
        return metas[i].getAttribute('content');
      }
    }
    return '';
  }

  this.fetchData = function (
    url,
    method,
    data,
    successCallback,
    failedCallback,
    headers =
      {'Accept':'application/json', 'Content-Type' : 'application/json'}
  )
  {
    const fullurl = url;
    const xhrOptions = {
      method: method,
      headers: {
        "X-CSRF-Token": _self.getMeta('csrf-token')
      },
      mode: 'cors',
      cache: 'default'
    };

    /* merge current headers with external headers */
    xhrOptions.headers = {...xhrOptions.headers,...headers};

    if (data !== undefined) {
      xhrOptions.body = data;
    }

    return fetch(fullurl, xhrOptions)
      .then(function (response) {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        let result;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          result = response.json();
        }else if (contentType && contentType.indexOf("text/html") !== -1) {
          result = response.text();
        }else {
          result =  response.blob();
        }
        if (response.status >= 400) {
          return result.then(resp => {
            if (contentType && contentType.indexOf("application/json") !== -1) {
              throw new Error(resp.message)
            }

            throw new Error(resp)

          });
        }
        return result;
      })
      .then(successCallback)
      .catch(failedCallback);
  }

  return {
    fetchData: this.fetchData,
  }
};
